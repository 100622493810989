import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getRentings, updateRenting } from '../../../api/rentingsAPI';
import { performActionable } from '../../../api/dynamicAPI';
import { RentingStatus, SearchType } from '../../../app/constants';
import { createAppointment } from '../../../api/appointmentAPI';
import { updateListing } from '../../../api/listingsAPI';
import { updateVehicle } from '../../../api/vehicleAPI';
import { addFilter } from '../../../app/utils';

export const masterlistSlice = createSlice({
  name: 'masterlist',
  initialState: {
    rentings: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'startDate',
    searchValue: '',
    selectedId: null,
    selectedRenting: null,
    selectedFilter: {
      parkingAreaName: null,
      businessType: null
    },
    editRentingModalOpen: false,
    editListingModalOpen: false,
    createAppointmentModalOpen: false,
    terminateModalOpen: false,
    swapParkingModalOpen: false,
    freezeRentingModalOpen: false,
    filterModalOpen: false,
    rentingActionUrl: "",
    rentingActionMethod: "",
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedRenting: (state, action) => {
      state.selectedRenting = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    showEditRentingModal: (state, action) => {
      state.editRentingModalOpen = action.payload;
    },
    showEditListingModal: (state, action) => {
      state.editListingModalOpen = action.payload;
    },
    showCreateAppointmentModal: (state, action) => {
      state.createAppointmentModalOpen = action.payload;
    },
    showTerminateModal: (state, action) => {
      state.terminateModalOpen = action.payload;
    },
    showSwapParkingModal: (state, action) => {
      state.swapParkingModalOpen = action.payload;
    },
    showFreezeRentingModal: (state, action) => {
      state.freezeRentingModalOpen = action.payload;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setRentingActionUrl: (state, action) => {
      state.rentingActionUrl = action.payload;
    },
    setRentingActionMethod: (state, action) => {
      state.rentingActionMethod = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setSelectedFilter, showFilterModal, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setSearchValue, setRentings, setSelectedId, setSelectedRenting, showEditRentingModal, showEditListingModal, showCreateAppointmentModal, showTerminateModal, showSwapParkingModal, showFreezeRentingModal, setRentingActionUrl, setRentingActionMethod, setSuccessMessage, setErrorMessage } = masterlistSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.masterlist;

export const handleGetRentings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedFilter } = state.masterlist;

    addFilter(filterParamsArray, 'listing.parkingBay.parkingAreaName', selectedFilter.parkingAreaName);
    addFilter(filterParamsArray, 'listing.additionalInformation.businessType', selectedFilter.businessType);
    let filterParams = filterParamsArray.join(',');

    const response = await getRentings(state.masterlist.page, state.masterlist.rowsPerPage, state.masterlist.sort, state.masterlist.sortBy, `${RentingStatus.ACTIVE},${RentingStatus.TERMINATING},${RentingStatus.OWNER_TERMINATING}`, state.masterlist.searchValue, SearchType.MASTERLIST, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setRentings(content));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateRenting = (renting) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateRenting(state.masterlist.selectedId, renting);
    dispatch(showEditRentingModal(false));
    dispatch(setSuccessMessage("Successfully updated renting!"));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateVehicle = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateVehicle(id, payload);
    dispatch(showEditRentingModal(false));
    dispatch(setSuccessMessage("Successfully updated vehicle!"));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update vehicle: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateListing = (listing) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateListing(state.masterlist.selectedRenting.listing.id, listing);
    dispatch(showEditListingModal(false));
    dispatch(setSuccessMessage("Successfully updated masterlist's listing!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleCreateAppointment = (appointment) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await createAppointment(appointment);
    dispatch(showCreateAppointmentModal(false));
    dispatch(setSuccessMessage("Successfully create appointment!"));
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleTerminateRenting = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.masterlist.rentingActionUrl, state.masterlist.rentingActionMethod, payload);
    dispatch(showTerminateModal(false));
    dispatch(setSuccessMessage("Successfully sent terminate request!"));
    dispatch(handleGetRentings);
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleSwapParking = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.masterlist.rentingActionUrl, state.masterlist.rentingActionMethod, payload);
    dispatch(showSwapParkingModal(false));
    dispatch(setSuccessMessage("Successfully Swap Parking Bay!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleFreezeRenting = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.masterlist.rentingActionUrl, state.masterlist.rentingActionMethod, null);
    dispatch(showFreezeRentingModal(false));
    dispatch(setSuccessMessage("Successfully sent freeze request!"));
    dispatch(handleGetRentings);
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default masterlistSlice.reducer;