import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter, InputBase, IconButton, debounce } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { setSearchValue, handleGetCustomers, selectState, setSelectedUserId, setSelectedId, setSelectedCustomer, setPage, setRowsPerPage, showAdminTopUpModal, showWalletTransactionModal, setSelectedWalletId, setSuccessMessage, setErrorMessage } from './customerSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import SearchIcon from '@mui/icons-material/Search';

import useToasts from '../../../hooks/useToasts';
import TablePaginationActions from './TablePaginationActions';
import styles from './Customer.module.css';
import { WalletTransactions } from '../../wallet/WalletTransactions';
import { AdminTopUp } from './AdminTopUp';
import { centToDollar } from '../../../app/utils';
import EditCustomerModal from './EditCustomerModal';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function Customer() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();
  
  useEffect(() => {
      dispatch(handleGetCustomers());
  }, [state.sort, state.sortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetCustomers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetCustomers());
  };

  const onSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setPage(0));
    dispatch(setSearchValue(cleanValue));
    dispatch(handleGetCustomers());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className={styles.container}>
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar 
                selectedId={state.selectedUserId}
                selectedUserId={state.selectedUserId}
                numSelected={state.selectedId === null ? 0 : 1}
                adminTopUp={() => dispatch(showAdminTopUpModal(true))}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Company</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Wallet Balance</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Birthday</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bank Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Account Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Account Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle</TableCell>
                <TableCell align="left" className={styles.tableHeader}>IC Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Passport Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Occupation</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Active Parking</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Active Listing</TableCell>
                <TableCell align="left" className={styles.tableHeader}>B2B Owner</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Insurance Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User's Rating</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User's Review</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.customers.length > 0 ? (
                  state.customers.map((customer) => (
                    <TableRow 
                      key={customer.id}
                      hover
                      onClick={() => {
                        dispatch(setSelectedId(state.selectedId === customer.id ? null : customer.id));
                        dispatch(setSelectedUserId(state.selectedId === customer.id ? null : customer.user.id));
                        dispatch(setSelectedCustomer(state.selectedId === customer.id ? null : customer));
                      }}
                      role="checkbox"
                      aria-checked={state.selectedId === customer.id ? true : false}
                      selected={state.selectedId === customer.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId === customer.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.firstName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.lastName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.company}</TableCell>
                      <TableCell align="left" className={styles.tableCell}
                        onClick={() => {
                          dispatch(setSelectedWalletId(customer.user?.wallet?.id))
                          dispatch(showWalletTransactionModal(true))
                        }}
                        style={{cursor: "pointer"}}
                      >{centToDollar(customer.user?.wallet?.balanceAmount || 0)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.birthDay}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.wallet?.bank?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.wallet?.bank?.accountNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.wallet?.bank?.accountName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.vehicles?.length > 0 ? `${customer.vehicles[0].registrationNumber} (${customer.vehicles[0].color} ${customer.vehicles[0].model})` : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.identificationCard}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.passportNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.occupation}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.userType}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.activeParking}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.activeListing}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.b2b}</TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{customer.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.insurance?.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.review?.recommendRating}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.review?.additionalThought}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <p>No Customers</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={11}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {state.editCustomerModalOpen && <EditCustomerModal />}
        {
          state.adminTopUpModalOpen &&
          <AdminTopUp selectedUserId={state.selectedUserId} />
        }
        {
          state.walletTransactionModalOpen &&
          <WalletTransactions walletId={state.selectedWalletId} closeModal={() => dispatch(showWalletTransactionModal(false))} user={state.selectedCustomer ? state.selectedCustomer.user : null} />
        }
      </div>
    </div>
  );
}
