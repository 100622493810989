import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { setPage, selectState, setSelectedFilter, showFilterModal } from './subscriptionSlice';
import { SubscriptionChargeStatus } from '../../../app/constants';

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const selectedFilter = state.selectedFilter;
  const [chargeStatus, setChargeStatus] = useState(selectedFilter.chargeStatus || [])

  const submit = () => {
    try {
      dispatch(setPage(0));
      dispatch(setSelectedFilter({
        chargeStatus
      }));
      dispatch(showFilterModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-subscription-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] h-[30%] max-w-[500px] max-h-[40%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Subscriptions</p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl margin="normal" variant="outlined" fullWidth>
                    <InputLabel htmlFor="charge-status">Subscription Charge Status</InputLabel>
                    <Select
                        fullWidth
                        multiple
                        onChange={(e) => setChargeStatus(e.target.value)}
                        value={chargeStatus}
                        input={<OutlinedInput name="chargeStatus" id="chargeStatus" label="Subscription Charge Status" />}
                    >
                    <MenuItem key={SubscriptionChargeStatus.PENDING} value={SubscriptionChargeStatus.PENDING}>{SubscriptionChargeStatus.PENDING}</MenuItem>
                    <MenuItem key={SubscriptionChargeStatus.PAID} value={SubscriptionChargeStatus.PAID}>{SubscriptionChargeStatus.PAID}</MenuItem>
                    <MenuItem key={SubscriptionChargeStatus.PAUSE_CHARGE} value={SubscriptionChargeStatus.PAUSE_CHARGE}>{SubscriptionChargeStatus.PAUSE_CHARGE}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} className="flex flex-grow justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;