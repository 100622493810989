import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, TableFooter, IconButton } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import { ConfirmRetryPayment } from './ConfirmRetryPayment';
import { setFiltering, showFilterModal, openRecreateSubscriptionModal, setSelectedSubscription, setSuccessMessage, setErrorMessage, handleGetSubscriptions, selectState, setPage, setRowsPerPage, handleGetFailedSubscriptions, setFailedSubscriptionPage, setFailedSubscriptionRowsPerPage, setSelectedFailedSubscriptionId, openConfirmRetryPaymentModal, setSelectedId, setSelectedWalletId, openConfirmPauseChargeModal } from './subscriptionSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './Subscription.module.css';
import FilterListIcon from '@mui/icons-material/FilterList';

import { centToDollar, formatCurrency, calculateDifferenceInDays } from '../../app/utils';
import { StickyTableHead } from '../../helpers/GeneralComponent';
import { ConfirmPauseChargeModal } from './ConfirmPauseChargeModal';
import { RecreateSubscriptionModal } from './RecreateSubscriptionModal';
import useToasts from '../../hooks/useToasts';
import FilterSubscriptionModal from './FilterSubscriptionModal';

export function Subscription() {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetSubscriptions(id));
  }, [state.selectedSubscriptionFilter, dispatch, id]);

  useEffect(() => {
    dispatch(handleGetFailedSubscriptions(id));
  }, [state.selectedFailedSubscriptionFilter, dispatch, id]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);
  
  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetSubscriptions(id));
  };

  const handleChangeFailedSubscriptionPage = (event, newPage) => {
    dispatch(setFailedSubscriptionPage(newPage));
    dispatch(handleGetFailedSubscriptions(id));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetSubscriptions(id));
  };

  const handleChangeFailedSubscriptionRowsPerPage = (event) => {
    dispatch(setFailedSubscriptionRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setFailedSubscriptionPage(0));
    dispatch(handleGetFailedSubscriptions(id));
  };

  return (
    <div className={styles.container}>
      {state.user && (
        <div className="mb-5">
          <h1 className="text-2xl font-bold">{`${state.user.firstName} ${state.user.lastName}`}</h1>
          <p className="text-slate-500 font-medium">{state.user.userName}</p>
        </div>
      )}
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 lg:col-span-9">
          <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
            <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white flex items-center justify-between">
              <div className="h-[64px] flex items-center">
                <TableToolbar title="All Subscriptions" />
              </div>
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => { dispatch(setFiltering("subscription")); dispatch(showFilterModal(true)) }}
                className="mr-[10px]"
              >
                <FilterListIcon />
              </IconButton>
            </div>
            <Table size="small">
              <StickyTableHead>
                <TableRow>
                  <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Phone</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Charge Status</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Subscription Type</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Subscription Interval</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Subscription Unit Amount</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Next Billing Date</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Action</TableCell>
                </TableRow>
              </StickyTableHead>
              <TableBody>
                {
                  state.subscriptions.length > 0 ? (
                    state.subscriptions.map((subscription) => (
                      <TableRow key={subscription.id}>
                        <TableCell align="left" className="min-w-[120px] break-all">{subscription.id}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.contactNumber}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.parkingArea?.name}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.parkingBay?.bayNumber}</TableCell>
                        <TableCell align="left" className="min-w-[120px] break-words">{subscription.sellPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.sellPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.status}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.chargeStatus}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.type}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.interval}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.unitAmount ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.unitAmount)}` : 'N/A'}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{`${subscription.owner?.user?.firstName || 'N/A'} ${subscription.owner?.user?.lastName || ''}`}</TableCell>
                        <TableCell align="left" className="min-w-[120px] break-words">{subscription.buyPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.buyPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>{subscription.nextBillingDate}</TableCell>
                        <TableCell align="left" className={styles.tableCell}>
                          <div className="flex space-x-2">
                            <button 
                              className="text-blue-500 text-left" 
                              onClick={() => {
                                dispatch(setSelectedId(subscription.id));
                                dispatch(setSelectedWalletId(subscription.walletId))
                                dispatch(openConfirmPauseChargeModal());
                              }}
                            >
                              Pause charge
                            </button>
                            <button 
                              className="text-blue-500 text-left" 
                              onClick={() => {
                                dispatch(setSelectedSubscription(subscription));
                                dispatch(openRecreateSubscriptionModal());
                              }}
                            >
                              Recreate Subscription
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <p>No subscriptions.</p>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    colSpan={6}
                    count={state.pagination?.totalElements || 0}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
            <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white flex items-center justify-between">
              <div className="h-[64px] flex items-center">
                <TableToolbar title="Failed Subscriptions" />
              </div>
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => { dispatch(setFiltering("failed")); dispatch(showFilterModal(true)) }}
                className="mr-[10px]"
              >
                <FilterListIcon />
              </IconButton>
            </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Phone</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Charge Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Retry Attempts</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Interval</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Unit Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Next Billing Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Age</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Action</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.failedSubscriptions.length > 0 ? (
                  state.failedSubscriptions.map((subscription) => (
                    <TableRow key={subscription.id}>
                      <TableCell align="left" className="min-w-[120px] break-all">{subscription.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingBay?.bayNumber}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.sellPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.sellPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.chargeStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.numberOfRetries || 0}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.type}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.interval}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.unitAmount ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.unitAmount)}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${subscription.owner?.user?.firstName || 'N/A'} ${subscription.owner?.user?.lastName || ''}`}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.buyPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.buyPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.nextBillingDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>
                        <span className={calculateDifferenceInDays(subscription.nextBillingDate) >= 7 ? styles.highlightedText : ''}>
                          {calculateDifferenceInDays(subscription.nextBillingDate)}
                        </span>
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>
                        <div className="flex space-x-2">
                          <button 
                            className="text-blue-500 text-left" 
                            onClick={() => {
                              dispatch(setSelectedId(subscription.id));
                              dispatch(setSelectedWalletId(subscription.walletId))
                              dispatch(openConfirmPauseChargeModal());
                            }}
                          >
                            Pause charge
                          </button>
                          <button 
                            className="text-blue-500 text-left" 
                            onClick={() => {
                              dispatch(setSelectedFailedSubscriptionId(subscription.id));
                              dispatch(openConfirmRetryPaymentModal());
                            }}
                          >
                            Retry payment
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      <p>No failed subscriptions.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={6}
                  count={state.failedSubscriptionPagination?.totalElements || 0}
                  rowsPerPage={state.failedSubscriptionRowsPerPage}
                  page={state.failedSubscriptionPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeFailedSubscriptionPage}
                  onRowsPerPageChange={handleChangeFailedSubscriptionRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {state.confirmRetryPaymentModalOpen && (
          <ConfirmRetryPayment selectedId={state.selectedFailedSubscriptionId} />
        )}
        {state.confirmPauseChargeModalOpen  &&
          <ConfirmPauseChargeModal selectedId={state.selectedId} />
        }
        {state.recreateSubscriptionModalOpen &&
          <RecreateSubscriptionModal />
        }
        {state.filterModalOpen && <FilterSubscriptionModal />}
        </div>
        {state.wallet && (
          <Paper className="col-span-12 lg:col-span-3 p-5 flex flex-col gap-4">
            {state.wallet.stripeInfo?.customerId && (
              <div>
                <p className="text-sm font-medium">Stripe Customer ID</p>
                <a href={`https://dashboard.stripe.com/customers/${state.wallet.stripeInfo.customerId}`} target="_blank" rel="noreferrer" className="text-sm text-blue-500 font-medium">{state.wallet.stripeInfo.customerId}</a>
              </div>
            )}
            <div>
              <p className="text-sm font-medium">Balance amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.balanceAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Holding amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.holdingAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total refunded amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalRefundedAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total spent amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalSpentAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total top up amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalTopUpAmount)}</p>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
}
