import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, TableFooter, IconButton } from "@mui/material";
import styles from './Subscription.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import FilterListIcon from '@mui/icons-material/FilterList';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { showFilterModal, openRecreateSubscriptionModal, setSelectedSubscription, selectState, setSuccessMessage, setErrorMessage, handleGeSubscriptions, setPage, setRowsPerPage, setSelectedId, setSelectedWalletId, openConfirmPauseChargeModal } from './subscriptionSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar, formatCurrency } from '../../../app/utils';
import { ConfirmPauseChargeModal } from './ConfirmPauseChargeModal';
import { RecreateSubscriptionModal } from './RecreateSubscriptionModal';
import FilterSubscriptionModal from './FilterSubscriptionModal';


export function Subscription() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGeSubscriptions());
  }, [state.selectedFilter, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGeSubscriptions());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGeSubscriptions());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 pr-3 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white flex items-center justify-between">
            <div className="h-[64px] flex items-center">
              <TableToolbar title="All Subscriptions" />
            </div>
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={() => dispatch(showFilterModal(true))}
              className="mr-[10px]"
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Username</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Phone</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Charge Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Interval</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Unit Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Next Billing Date</TableCell>
                <TableCell align="center" className={styles.tableHeader}>Action</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.subscriptions.length > 0 ? (
                  state.subscriptions.map((subscription) => (
                    <TableRow key={subscription.id}>
                      <TableCell align="left" className="min-w-[120px] break-all"><Link className="text-blue-500" to={`/parkit/wallets/${subscription.walletId}/subscriptions/${subscription.id}`} target='_blank'>{subscription.id}</Link></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.firstName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.lastName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Link className="text-blue-500" to={`/users/${subscription.renter?.user?.id}/subscriptions`} target='_blank'>{subscription.renter?.user?.userName}</Link></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingBay?.bayNumber}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.sellPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.sellPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.chargeStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.type}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.interval}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.unitAmount ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.unitAmount)}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${subscription.owner?.user?.firstName || 'N/A'} ${subscription.owner?.user?.lastName || ''}`}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.buyPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.buyPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.nextBillingDate}</TableCell>
                      <TableCell align="left" className={styles.tableCellAction}>
                        <div className="flex space-x-2">
                          <button 
                            className="text-blue-500 text-left" 
                            onClick={() => {
                              dispatch(setSelectedId(subscription.id));
                              dispatch(setSelectedWalletId(subscription.walletId))
                              dispatch(openConfirmPauseChargeModal());
                            }}
                          >
                            Pause charge
                          </button>
                          <button 
                            className="text-blue-500 text-left" 
                            onClick={() => {
                              dispatch(setSelectedSubscription(subscription));
                              dispatch(openRecreateSubscriptionModal());
                            }}
                          >
                            Recreate Subscription
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      <p>No subscriptions.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={6}
                  count={state.pagination?.totalElements || 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.confirmPauseChargeModalOpen  && <ConfirmPauseChargeModal selectedId={state.selectedId} />}
      {state.recreateSubscriptionModalOpen && <RecreateSubscriptionModal />}
      {state.filterModalOpen && <FilterSubscriptionModal />}
    </div>
  );
}