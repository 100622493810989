import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { setPage, selectState, setSelectedFilter, showFilterModal } from './listingSlice';
import { RentingStatus, ListingStatus } from '../../../app/constants';

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  const selectedFilter = state.selectedFilter;
  const [status, setStatus] = useState(selectedFilter.status);
  const [rentingStatus, setRentingStatus] = useState(selectedFilter.rentingStatus);
  const [dedicatedSpace, setDedicatedSpace] = useState(selectedFilter.dedicatedSpace);
  const [buildingType, setBuildingType] = useState(selectedFilter.buildingType);
  const [tandem, setTandem] = useState(selectedFilter.tandem);
  const [paymentRemark, setPaymentRemark] = useState(selectedFilter.paymentRemark);

  const submit = () => {
    try {
      dispatch(setPage(0));
      dispatch(setSelectedFilter({
        status,
        rentingStatus,
        dedicatedSpace,
        buildingType,
        tandem,
        paymentRemark
      }));
      dispatch(showFilterModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-listing-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] h-[50%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Listings</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="status">Driver Status</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setRentingStatus(e.target.value)}
                  value={rentingStatus}
                  input={<OutlinedInput name="status" id="status" label="Driver Status" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  {Object.keys(RentingStatus).map((key) => (
                    <MenuItem key={key} value={RentingStatus[key]}>
                      {RentingStatus[key].replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="listing-status">Owner Status</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  input={<OutlinedInput name="listingStatus" id="listing-status" label="Owner Status" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key={ListingStatus.VACANT} value={ListingStatus.VACANT}>{ListingStatus.VACANT}</MenuItem>
                  <MenuItem key={ListingStatus.OCCUPIED} value={ListingStatus.OCCUPIED}>{ListingStatus.OCCUPIED}</MenuItem>
                  <MenuItem key={ListingStatus.ACTIVE} value={ListingStatus.ACTIVE}>{ListingStatus.ACTIVE}</MenuItem>
                  <MenuItem key={ListingStatus.INACTIVE} value={ListingStatus.INACTIVE}>{ListingStatus.INACTIVE}</MenuItem>
                  <MenuItem key={ListingStatus.TERMINATING} value={ListingStatus.TERMINATING}>{ListingStatus.TERMINATING}</MenuItem>
                  <MenuItem key={ListingStatus.ALLOCATED} value={ListingStatus.ALLOCATED}>{ListingStatus.ALLOCATED}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="building-type">Building Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBuildingType(e.target.value)}
                  value={buildingType}
                  input={<OutlinedInput name="buildingType" id="building-type" label="Building Type" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="LANDED" value="LANDED">Landed</MenuItem>
                  <MenuItem key="APARTMENT" value="APARTMENT">Apartment</MenuItem>
                  <MenuItem key="COMMERCIAL" value="COMMERCIAL">Commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="dedicated-space">Dedicated Space</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setDedicatedSpace(e.target.value)}
                  value={dedicatedSpace}
                  input={<OutlinedInput name="dedicatedSpace" id="dedicated-space" label="Dedicated Space" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="RESERVED" value="true">RESERVED</MenuItem>
                  <MenuItem key="FLOATING" value="false">FLOATING</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="tandem">Tandem space</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setTandem(e.target.value)}
                  value={tandem}
                  input={<OutlinedInput name="tandem" id="tandem" label="Tandem Space" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="YES" value="true">YES</MenuItem>
                  <MenuItem key="NO" value="false">NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="paymentRemark"
                label="Payment Remark"
                onChange={(e) => setPaymentRemark(e.target.value)}
                value={paymentRemark}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex flex-grow justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;