import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, IconButton, Typography, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "../../../hooks/useDisableWhileRequesting";

import { handleApproveAndTerminateRenting, showApproveAndTerminateRentingModal, selectState } from './terminationSlice';

const ApproveAndTerminateRentingModal = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectState);

  const renting = state.selectedRenting;
  const selectedUserId = renting.customer?.user?.id;
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [reminder, setReminder] = useState(null)

  useEffect(() => {
    const customerDueDate = new Date(renting.termination.customerDueDate);
    customerDueDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (customerDueDate < today) {
      setReminder("Finance need to manual refund for overcharges.");
    } else if (customerDueDate > today) {
      setReminder("Finance need to manual charge if extra charges required to cover gap between termination date and actual parking date.");
    } else {
      setReminder("");
    }
  }, [renting]);

  const submit = async () => {
    try {
      await withDisable(() => dispatch(handleApproveAndTerminateRenting()));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="approve-renting-termination-modal"
      className="flex items-center justify-center"
    >
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          padding: "24px",
          width: "90%",
          maxWidth: "600px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => dispatch(showApproveAndTerminateRentingModal(false))}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: "#9E9E9E",
          }}
        >
          <Close />
        </IconButton>

        <Typography
          variant="h6"
          align="center"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: "16px",
          }}
        >
          Approve and Terminate Renting
        </Typography>

        <Typography
          variant="body1"
          align="center"
          sx={{ color: "#555", marginBottom: "8px" }}
        >
          Are you sure you want to approve and terminate this renting?
        </Typography>
        <Typography
          variant="body2"
          align="center"
          onClick={() => window.open(`/users/${selectedUserId}/subscriptions`, '_blank')}
          sx={{
            color: "#1a0dab",
            marginBottom: "16px",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": { color: "#555" },
          }}
        >
          Pause subscription if retry failed payment not necessary
        </Typography>

        {reminder && reminder.trim() !== "" && (
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "red",
              fontWeight: "bold",
              marginBottom: "16px",
            }}
          >
            {reminder}
          </Typography>
        )}

        <Box display="flex" justifyContent="center" gap="16px">
          <button
            onClick={submit}
            disabled={isDisabled}
            className={`flex bg-[#3085D6] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px] ${
              isDisabled
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                : 'bg-[#DE4D65] text-white cursor-pointer'
            }`}
          >
            Yes
          </button>
          <button
            onClick={() => dispatch(showApproveAndTerminateRentingModal(false))}
            className="flex bg-[#E86C6D] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px]"
          >
            No
          </button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default ApproveAndTerminateRentingModal;